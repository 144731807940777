import { $W } from '@wix/blocks-widget-services-types';
import model from './model';
import { localeCode } from '../../assets/constants/variables';

export default model.createController(({ $widget, flowAPI, $w, $bind }) => {
  const language: any = flowAPI.environment.language;
  const languageValue = localeCode[language] ?? localeCode.en;
  let myParams = { ...$widget.props, url: '' };
  let onMessageListener = false;
  let sendingDataSent = false;

  const updateEmbedParams = (props) => {
    myParams = {
      ...$widget.props,
      ...props,
    };
  };
  const postMessageReader = (action) => {
    if (action === 'sendingData') {
      if ($w('#html1').onMessage) {
        if (!onMessageListener) {
          $w('#html1').onMessage((event) => {
            if (event.data.action === 'giveMeData') {
              $w('#html1').postMessage({
                ...myParams,
                newUrl: myParams.url,
                localeLang: languageValue,
                action,
              });
              sendingDataSent = true;
            }
          });
          onMessageListener = true;
        }
      }
    } else {
      $w('#html1').postMessage({
        ...myParams,
        newUrl: myParams.url,
        localeLang: languageValue,
        action,
      });
    }
  };

  return {
    pageReady: async ($win: $W) => {
      $widget.fireEvent('widgetLoaded', {});
      $win.onReady(() => {
        try {
          if (!sendingDataSent && myParams.url !== '') {
            $win('#html1').postMessage({
              ...myParams,
              newUrl: myParams.url,
              localeLang: languageValue,
              action: 'sendingData',
            });
            sendingDataSent = true;
          }
        } catch (e) {
          console.log('preview element was not ready yet', e);
        }
      });
    },
    exports: { postMessageReader, updateEmbedParams },
  };
});
